<template>
    <div class="container" :class="{'kk_lang': i18n().locale === 'kk'}">
      <transition name="fade">
        <card-modal v-if="showModal" @toggleModal="toggleModal"/>
      </transition>
      <transition name="fade">
        <gift-card-modal v-if="showGiftModal" @toggleModal="toggleGiftModal"/>
      </transition>
      <div class="banner">
        <div class="banner-info">
          <div class="banner-info__glow"></div>
          <div class="banner-info__stroke"></div>
          <div class="banner-info__text">
            <span>{{ $t('banner.text') }}</span>
          </div>
        </div>
        <h1
          :class="{'banner-info__title-kk': i18n().locale === 'kk'}"
          v-html="$t('banner.title')"
        ></h1>
        <h3 v-html="$t('banner.subtitle')"></h3>
      </div>

      <img
        v-for="img in cloudImages"
        :key="img"
        :class="img"
        :src="require(`@/assets/images/container-images/${img}.png`)"
        alt=""
      >

      <div class="card-block">
        <img
          class="card-block__img card-block__img_gradient"
          src="@/assets/images/container-images/container-gradient.png"
          alt=""
        >
        <img
          class="naruto-img"
          src="@/assets/images/naruto-bg.png"
          alt="naruto"
        >
        <img
          class="sasuke-img"
          src="@/assets/images/sasuke-bg.png"
          alt="sasuke"
        >
        <div class="cards">
          <div class="cards__wrapper">
            <div class="cards__wrapper__video">
              <video autoplay muted loop playsinline preload="auto" style="pointer-events: none;">
                <source v-if="isSafari" :src="mainVideo" type="video/mp4">
                <source src="../assets/video/main-video.webm" type="video/webm">
              </video>
            </div>
          </div>
        </div>
        <div class="cards-logos">
          <img src="@/assets/images/cards/cards-logo.png" alt="">
        </div>
        <div class="cards-info">
          <img src="@/assets/images/coin.png" alt="coin">
          <p>{{ $t('cards.text') }}</p>
          <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" class="get-card__btn-border">
            <div class="get-card__btn">
              {{ $t('global.get-card') }}
            </div>
          </a>
        </div>
      </div>

      <div class="originality content">
        <h2 class="originality__title" v-html="$t('originality.title')"></h2>
        <div class="originality__content">
          <div class="originality__content-top">
            <div class="originality__text-border">
              <div class="originality__text">
                <div class="originality__gradient"></div>
                <h4>{{ $t('originality.text.title') }}</h4>
                <p
                  :class="{'originality__text-kk': i18n().locale === 'kk'}"
                >{{ $t('originality.text.subtitle') }}</p>
                <div v-html="$t('originality.text.notice')" class="originality__text-notice">
                </div>
                <video autoplay muted loop playsinline preload="auto" style="pointer-events: none;">
                  <source v-if="isSafari" :src="narutoMovVideo" type="video/mp4">
                  <source src="../assets/video/originality-card-video.webm" type="video/webm">
                </video>
              </div>
            </div>
            <div class="originality__qr-border">
              <div class="originality__qr">
                <div class="originality__qr-top">
                  <img src="@/assets/images/originality/logo.png" alt="logo">
                  <p>{{ $t('originality.qr.open-card') }}</p>
                </div>
                <div class="originality__qr-bottom">
                  <img src="@/assets/images/qr.png" alt="qr code">
                  <p>{{ $t('originality.qr.scan') }}</p>
                </div>
                <div class="originality__qr-mobile-btns">
                  <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank" class="originality__qr-mobile-btn">
                    <img src="@/assets/images/app-store.svg" alt="app store">
                    <span>App Store</span>
                  </a>
                  <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank" class="originality__qr-mobile-btn">
                    <img src="@/assets/images/google-play.svg" alt="play market">
                    <span>Play Market</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="originality__content-bottom"><p>©2002 MK</p><p style="margin-left: 10px;">©2002MK - 2007SP</p></div>
        </div>
      </div>

      <div class="benefit content">
        <h2 class="title title_margin" v-html="$t('benefit.title')"></h2>
        <!-- <div class="benefit__tabs">
          <div class="benefit__tab" :class="{active: activeBenefitTab === 1}" @click="activeBenefitTab = 1">SuperCard</div>
          <div class="benefit__tab" :class="{active: activeBenefitTab === 2}" @click="activeBenefitTab = 2">Gift Card</div>
        </div> -->
        <div v-show="activeBenefitTab === 1" class="benefit__content">
          <div class="benefit__left">
            <div class="benefit__gradient"></div>
            <h4>{{ $t('benefit.content.title1') }}</h4>
            <p>{{ $t('benefit.content.subtitle1') }}</p>
            <div class="benefit__btn-wrapper">
              <div class="benefit__btn" @click="toggleModal(true)">{{ $t('benefit.content.btn') }}</div>
            </div>
          </div>
          <div class="benefit__right">
            <div class="benefit__right-top">
              <div class="benefit__gradient"></div>
              <h4>{{ $t('benefit.content.title2') }}</h4>
              <p
                :class="{'benefit__text-kk': i18n().locale === 'kk'}"
              >{{ $t('benefit.content.subtitle2') }}</p>
            </div>
            <div class="benefit__right-bottom">
              <div class="benefit__right-bottom__left">
                <div class="benefit__gradient"></div>
                <h4>{{ $t('benefit.content.title3') }}</h4>
                <p>{{ $t('benefit.content.subtitle3') }}</p>
              </div>
              <div class="benefit__right-bottom__right">
                <div class="benefit__gradient"></div>
                <h4>{{ $t('benefit.content.title4') }}</h4>
                <p>{{ $t('benefit.content.subtitle4') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="activeBenefitTab === 2" class="benefit__content benefit__gift">
          <div class="benefit__card" v-for="giftCard in giftCards" :key="giftCard.id">
            <div v-html="$t(giftCard.title)" class="benefit__card-title"></div>
            <div v-html="$t(giftCard.text)" class="benefit__card-text"></div>
            <div v-if="giftCard.btn" class="benefit__card-btn" @click="toggleGiftModal(true)">{{ $t(giftCard.btn) }}</div>
          </div>
        </div>
        <div v-show="activeBenefitTab === 1" class="benefit__mobile">
          <VueSlickCarousel
              ref="benefitSlider"
              class="benefit-slider"
              :focusOnSelect="true"
              v-bind="benefitSliderSettings"
          >
            <div class="benefit__mobile-slide">
              <h4>{{ $t('benefit.content.title1') }}</h4>
              <p>{{ $t('benefit.content.subtitle1') }}</p>
              <div class="benefit__btn-wrapper">
                <div class="benefit__btn" @click="toggleModal(true)">{{ $t('benefit.content.btn') }}</div>
              </div>
            </div>
            <div class="benefit__mobile-slide">
              <h4>{{ $t('benefit.content.title2') }}</h4>
              <p>{{ $t('benefit.content.subtitle2') }}</p>
            </div>
            <div class="benefit__mobile-slide">
              <h4 v-html="$t('benefit.content.title3-mobile')"></h4>
              <p>{{ $t('benefit.content.subtitle3') }}</p>
            </div>
            <div class="benefit__mobile-slide">
              <h4>{{ $t('benefit.content.title4') }}</h4>
              <p>{{ $t('benefit.content.subtitle4') }}</p>
            </div>
          </VueSlickCarousel>
        </div>
        <div v-show="activeBenefitTab === 2" class="benefit__mobile benefit__mobile_gift">
          <VueSlickCarousel
              ref="benefitGiftSlider"
              class="benefit-slider"
              :focusOnSelect="true"
              v-bind="benefitSliderSettings"
          >
            <div class="benefit__mobile-slide">
              <div>
                <h4 v-html="$t('benefit.gift-card.title1')"></h4>
                <p v-html="$t('benefit.gift-card.text1')"></p>
              </div>
              <div class="benefit__card-btn" @click="toggleGiftModal(true)">{{ $t('benefit.gift-card.btn') }}</div>
            </div>
            <div class="benefit__mobile-slide">
              <h4 v-html="$t('benefit.gift-card.title2')"></h4>
              <p v-html="$t('benefit.gift-card.text2')"></p>
            </div>
            <div class="benefit__mobile-slide">
              <h4 v-html="$t('benefit.gift-card.title3')"></h4>
              <p v-html="$t('benefit.gift-card.text3')"></p>
            </div>
            <div class="benefit__mobile-slide">
              <h4 v-html="$t('benefit.gift-card.title4')"></h4>
              <p v-html="$t('benefit.gift-card.text4')"></p>
            </div>
          </VueSlickCarousel>
        </div>
        <div v-show="activeBenefitTab === 1" class="benefit__info">
          <p :class="{'benefit__info-kk': i18n().locale === 'kk'}">{{ $t('benefit.info') }}</p>
        </div>
      </div>

      <div class="instruction content">
        <h2 class="instruction__title title title_margin" v-html="$t('instruction.title')"></h2>
        <!-- <div class="instruction__tabs">
          <div class="instruction__tab" :class="{active: activeInstructionTab === 1}" @click="activeInstructionTab = 1">SuperCard</div>
          <div class="instruction__tab" :class="{active: activeInstructionTab === 2}" @click="activeInstructionTab = 2">Gift Card</div>
        </div> -->
        <div v-show="activeInstructionTab === 1" class="instruction__content">
          <div class="instruction__items">
            <div
                class="instruction__item-border"
                :class="{'active': instruction.id === currentInstructionId}"
                @mouseover="currentInstructionId = instruction.id"
                v-for="instruction in instructions"
                :key="instruction.id"
            >
              <div class="instruction__item">
                <div class="instruction__item-number">
                  <span>{{ instruction.id }}</span>
                </div>
                <div class="instruction__item-text">
                  <h5>{{ $t(instruction.title) }}</h5>
                  <p v-if="instruction.description">{{ $t(instruction.description) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="instruction__img">
            <img :src="require(`@/assets/images/instruction/${currentInstruction.img}_${i18n().locale}.png`)" alt="">
          </div>
        </div>
        <div v-show="activeInstructionTab === 2" class="instruction__content">
          <div class="instruction__items">
            <div
                class="instruction__item-border"
                :class="{'active': instruction.id === currentGiftInstructionId}"
                @mouseover="currentGiftInstructionId = instruction.id"
                v-for="instruction in giftInstructions"
                :key="'gift_' + instruction.id"
            >
              <div class="instruction__item">
                <div class="instruction__item-number">
                  <span>{{ instruction.id }}</span>
                </div>
                <div class="instruction__item-text">
                  <h5>{{ $t(instruction.title) }}</h5>
                  <p v-if="instruction.description">{{ $t(instruction.description) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="instruction__img">
            <!-- <img :src="require(`@/assets/images/gift-instruction/${currentGiftInstruction.img}_${i18n().locale}.png`)" alt=""> -->
          </div>
        </div>
        <div v-show="activeInstructionTab === 1" class="instruction__mobile">
          <VueSlickCarousel
              ref="imgSlider"
              class="img-slider"
              :asNavFor="textSlider"
              :focusOnSelect="true"
              v-bind="imgSliderSettings"
          >
            <div
                v-for="instruction in instructions"
                :key="instruction.id"
                class="instruction__mobile-img"
            >
              <img :src="require(`@/assets/images/instruction/${instruction.img}_${i18n().locale}.png`)" alt="">
            </div>
          </VueSlickCarousel>
          <VueSlickCarousel
              ref="textSlider"
              class="text-slider"
              :asNavFor="imgSlider"
              :focusOnSelect="true"
              v-bind="textSliderSettings"
          >
            <div
                v-for="instruction in instructions"
                :key="instruction.id"
                class="slider-text-item__border"
            >
              <div class="slider-text-item">
                <div class="slider-text-item__count">
                  <span>{{ instruction.id }}</span>
                </div>
                <h5>{{ $t(instruction.title) }}</h5>
                <p v-if="instruction.description">{{ $t(instruction.description) }}</p>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
        <div v-show="activeInstructionTab === 2" class="instruction__mobile">
          <VueSlickCarousel
              ref="imgGiftSlider"
              class="img-slider"
              :asNavFor="textGiftSlider"
              :focusOnSelect="true"
              v-bind="imgSliderSettings"
          >
            <div
              v-for="instruction in giftInstructions"
              :key="instruction.id"
              class="instruction__mobile-img"
            >
              <!-- <img :src="require(`@/assets/images/gift-instruction/${instruction.img}_${i18n().locale}.png`)" alt=""> -->
            </div>
          </VueSlickCarousel>
          <VueSlickCarousel
              ref="textGiftSlider"
              class="text-slider"
              :asNavFor="imgGiftSlider"
              :focusOnSelect="true"
              v-bind="textSliderSettings"
          >
            <div
                v-for="instruction in giftInstructions"
                :key="instruction.id"
                class="slider-text-item__border"
            >
              <div class="slider-text-item">
                <div class="slider-text-item__count">
                  <span>{{ instruction.id }}</span>
                </div>
                <h5>{{ $t(instruction.title) }}</h5>
                <p v-if="instruction.description">{{ $t(instruction.description) }}</p>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>

      <div class="qr">
        <div class="qr-text" :class="{'qr-text-kk': i18n().locale === 'kk'}">
          <h3 v-html="$t('qr.title')"></h3>
          <p>{{ $t('qr.subtitle') }}</p>
          <div class="qr-block__wrapper">
            <div class="qr-block">
              <img src="@/assets/images/qr.png" alt="qr">
              <div class="qr-block__text">{{ $t('qr.block-text') }}</div>
            </div>
          </div>
          <div class="qr-buttons">
            <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank" class="qr-btn">
              <img src="@/assets/images/app-store.svg" alt="app store">
              <span>App Store</span>
            </a>
            <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank" class="qr-btn">
              <img src="@/assets/images/google-play.svg" alt="play market">
              <span>Play Market</span>
            </a>
          </div>
        </div>
      </div>

      <div class="faq content">
        <h2 class="title title_margin" v-html="$t('faq.title')"></h2>
        <!-- <div class="faq__tabs">
          <div class="faq__tab" :class="{active: activeFaqTab === 1}" @click="activeFaqTab = 1">SuperCard</div>
          <div class="faq__tab" :class="{active: activeFaqTab === 2}" @click="activeFaqTab = 2">Gift Card</div>
        </div> -->
        <div v-show="activeFaqTab === 1" class="faq-list">
          <faq-item
              :header="$t('faq.item1.header')"
              :content="$t('faq.item1.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item2.header')"
              :content="$t('faq.item2.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item3.header')"
              :content="$t('faq.item3.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item4.header')"
              :content="$t('faq.item4.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item5.header')"
              :content="$t('faq.item5.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item6.header')"
              :content="$t('faq.item6.content')">
          </faq-item>
        </div>

        <div v-show="activeFaqTab === 2" class="faq-list">
          <faq-item
              :header="$t('gift-faq.item1.header')"
              :content="$t('gift-faq.item1.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item2.header')"
              :content="$t('gift-faq.item2.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item3.header')"
              :content="$t('gift-faq.item3.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item4.header')"
              :content="$t('gift-faq.item4.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item5.header')"
              :content="$t('gift-faq.item5.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item6.header')"
              :content="$t('gift-faq.item6.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item7.header')"
              :content="$t('gift-faq.item7.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item8.header')"
              :content="$t('gift-faq.item8.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item9.header')"
              :content="$t('gift-faq.item9.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item10.header')"
              :content="$t('gift-faq.item10.content')">
          </faq-item>
          <faq-item
              :header="$t('gift-faq.item11.header')"
              :content="$t('gift-faq.item11.content')">
          </faq-item>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import FaqItem from "@/components/FaqItem.vue";
import CardModal from "@/components/CardModal.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import mainVideo from '@/assets/video/main-video.mov';
import narutoMovVideo from '@/assets/video/originality-card-video.mov';
import GiftCardModal from "@/components/GiftCardModal.vue";

export default {
  name: "HomeView",
  data() {
    return {
      isIOSorMacOS: /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
      propositionInfo: false,
      aboutInfo: false,
      showModal: false,
      showGiftModal: false,
      currentInstructionId: 1,
      currentGiftInstructionId: 1,
      activeBenefitTab: 1,
      activeInstructionTab: 1,
      activeFaqTab: 1,
      narutoMovVideo,
      mainVideo,
      cloudImages: ['cloud-1', 'cloud-2', 'cloud-3', 'cloud-4'],
      instructions: [
        {
          id: 1,
          title: 'instruction.title1',
          description: 'instruction.subtitle1',
          img: "1"
        },
        {
          id: 2,
          title: 'instruction.title2',
          description: 'instruction.subtitle2',
          img: "2"
        },
        {
          id: 3,
          title: 'instruction.title3',
          description: 'instruction.subtitle3',
          img: "3"
        },
        {
          id: 4,
          title: 'instruction.title4',
          description: 'instruction.subtitle4',
          img: "4"
        },
        {
          id: 5,
          title: 'instruction.title5',
          img: "5"
        },
      ],
      giftInstructions: [
        {
          id: 1,
          title: 'gift-instruction.title1',
          description: 'gift-instruction.subtitle1',
          img: "1"
        },
        {
          id: 2,
          title: 'gift-instruction.title2',
          description: 'gift-instruction.subtitle2',
          img: "2"
        },
        {
          id: 3,
          title: 'gift-instruction.title3',
          description: 'gift-instruction.subtitle3',
          img: "3"
        },
        {
          id: 4,
          title: 'gift-instruction.title4',
          description: 'gift-instruction.subtitle4',
          img: "4"
        },
        {
          id: 5,
          title: 'gift-instruction.title5',
          description: 'gift-instruction.subtitle5',
          img: "5"
        },
        {
          id: 6,
          title: 'gift-instruction.title6',
          description: 'gift-instruction.subtitle6',
          img: "6"
        },
      ],
      benefitSliderSettings: {
        "dots": true,
        "arrows": false,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
      },
      imgSlider: undefined,
      textSlider: undefined,
      imgGiftSlider: undefined,
      textGiftSlider: undefined,
      imgSliderSettings: {
        "dots": false,
        "arrows": false,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "fade": true,
        "slidesToShow": 1,
      },
      textSliderSettings: {
        "dots": true,
        "arrows": false,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "fade": true,
        "slidesToShow": 1,
      },
      cardSlider: undefined,
      cards: [
        {
          id: 1,
          name: 'Joker',
          description: 'cards.card-1'
        },
        {
          id: 2,
          name: 'The Batman',
          description: 'cards.card-2'
        },
        {
          id: 3,
          name: 'Harley Quinn',
          description: 'cards.card-3'
        },
        {
          id: 4,
          name: 'Superman',
          description: 'cards.card-4'
        }
      ],
      giftCards: [
        {
          id: 1,
          title: 'benefit.gift-card.title1',
          text: 'benefit.gift-card.text1',
          btn: 'benefit.gift-card.btn'
        },
        {
          id: 2,
          title: 'benefit.gift-card.title2',
          text: 'benefit.gift-card.text2'
        },
        {
          id: 3,
          title: 'benefit.gift-card.title3',
          text: 'benefit.gift-card.text3'
        },
        {
          id: 4,
          title: 'benefit.gift-card.title4',
          text: 'benefit.gift-card.text4'
        }
      ]
    }
  },
  props: {
    isGift: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentInstruction() {
      return this.instructions.find(item => item.id === this.currentInstructionId);
    },
    currentGiftInstruction() {
      return this.giftInstructions.find(item => item.id === this.currentGiftInstructionId);
    },
    isSafari() {
      let userAgentString = navigator.userAgent;
      let chromeAgent     = userAgentString.indexOf("Chrome") > -1;
      let safariAgent     = userAgentString.indexOf("Safari") > -1;

      if ((chromeAgent) && (safariAgent)) safariAgent = false;

      return safariAgent;
    }
  },
  components: {
    GiftCardModal,
    CardModal,
    FaqItem,
    VueSlickCarousel
  },
  mounted() {
    let self = this
    setTimeout(function () {
      self.imgSlider = self.$refs.imgSlider;
      self.textSlider = self.$refs.textSlider;
      self.imgGiftSlider = self.$refs.imgGiftSlider;
      self.textGiftSlider = self.$refs.textGiftSlider;
      self.cardSlider = self.$refs.cardSlider;
    }, 100)

    window.onpageshow = function() {
      let videos = document.querySelectorAll('video');

      videos.forEach(function (video) {
        video.play()
      })
    };

    this.glowAnimation();

    if (this.isGift) {
      this.activeBenefitTab = 2;
      this.activeInstructionTab = 2;
      this.activeFaqTab = 2;
    }
  },
  watch: {
    showModal(val) {
      if (val) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
    showGiftModal(val) {
      if (val) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }
  },
  methods: {
    i18n() {
      return i18n
    },
    toggleModal(data) {
      this.showModal = data;
    },
    toggleGiftModal(data) {
      this.showGiftModal = data;
    },
    glowAnimation() {
      let x = 11,
          y = 0,
          valueX = 1,
          valueY = 2;

      let glow = document.querySelector('.banner-info__glow'),
          stroke = document.querySelector('.banner-info__stroke');

      setInterval(function () {
        if (x === 90 && y < 100) {
          valueX = -1;
          y += valueY;
        } else if (x === 10 && y > 0) {
          valueX = 1;
          y += valueY;
        } else if (y === 100) {
          valueY = -2;
          x += valueX;
        } else if (y === 0) {
          valueY = 2;
          x += valueX;
        }

        glow.style.background = 'radial-gradient(25% 40% at '+x+'% '+y+'%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)';
        stroke.style.background = 'radial-gradient(18.3344% 45.3822% at '+x+'% '+y+'%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)';
      }, 20)
    },
    touchStartMethod (touchEvent) {
      if (touchEvent.changedTouches.length !== 1) {
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      addEventListener('touchend', (touchEvent) => this.touchEndMethod(touchEvent, posXStart), {once: true});
    },
    touchEndMethod (touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) {
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      if (posXStart < posXEnd) {
        this.prevCard();
      } else if (posXStart > posXEnd) {
        this.nextCard();
      }
    }
  }
}

</script>
