<template>
  <div class="card-modal__layout" @click="toggleModal(false)">
    <div class="card-modal" @click.stop>
      <div class="card-modal__close" @click="toggleModal(false)"></div>
      <div class="card-modal__wrapper">
        <div class="card-modal__title">
          <p>{{ $t('card-modal.title') }}</p>
          <div class="cards-logos">
            <img src="@/assets/images/cards/cards-logo.png" alt="">
          </div>
        </div>
        <div class="card-modal__content">
          <div class="card-modal__left">
            <img src="@/assets/images/card-img.png" alt="cards">
            <div class="card-modal__left-text"><p>©2002 MK</p><p style="margin-left: 10px;">©2002MK - 2007SP</p></div>
          </div>
          <div class="card-modal__right">
            <div class="card-modal__table">
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-1.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('card-modal.table.row-1.title') }}</h5>
                  <div class="card-modal__table-row-items super-items">
                    <div class="card-modal__table-row-item" v-html="$t('card-modal.table.row-1.items.item-1')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('card-modal.table.row-1.items.item-2')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('card-modal.table.row-1.items.item-3')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('card-modal.table.row-1.items.item-4')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('card-modal.table.row-1.items.item-5')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('card-modal.table.row-1.items.item-6')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('card-modal.table.row-1.items.item-7')"></div>
                  </div>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-2.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('card-modal.table.row-2.title') }}</h5>
                  <p>{{ $t('card-modal.table.row-2.description') }}</p>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-3.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('card-modal.table.row-3.title') }}</h5>
                  <p>{{ $t('card-modal.table.row-3.description') }}</p>
                  <span>{{ $t('card-modal.table.row-3.info') }}</span>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-4.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('card-modal.table.row-4.title') }}</h5>
                  <p>{{ $t('card-modal.table.row-4.description') }}</p>
                  <span>{{ $t('card-modal.table.row-4.info') }}</span>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-5.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('card-modal.table.row-5.title') }}</h5>
                  <p>{{ $t('card-modal.table.row-5.description') }}</p>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-6.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('card-modal.table.row-6.title') }}</h5>
                  <p>{{ $t('card-modal.table.row-6.description') }}</p>
                </div>
              </div>
            </div>
            <div class="card-modal__info">
              <div class="card-modal__info-left">
                <div class="card-modal__info-icon">
                  <img src="@/assets/images/icons/info.svg" alt="info">
                </div>
                <p>{{ $t('card-modal.info.description') }}</p>
              </div>
              <div class="card-modal__info-right">
                <a :href="'/tariffs/tariffs_depositcard_'+i18n().locale+'.pdf'" target='_blank' class="card-modal__info-btn-border">
                  <div class="card-modal__info-btn">
                    {{ $t('card-modal.info.btn') }}
                  </div>
                </a>
                <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" class="get-card__btn-border">
                  <div class="get-card__btn">
                    {{ $t('global.get-card') }}
                  </div>
                </a>
              </div>
            </div>
            <div class="card-modal__left-text card-modal__left-text_mobile"><p>©2002 MK</p><p style="margin-left: 10px;">©2002MK - 2007SP</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import i18n from "@/i18n";

export default {
    name: 'CardModal',
    data() {
        return {

        }
    },
    methods: {
      toggleModal(data) {
        this.$emit('toggleModal', data);
      },
      i18n() {
        return i18n
      }
    }
}
</script>