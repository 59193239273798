<template>
    <div class="header">
        <a :href="'https://bankffin.kz/'+activeLocale" class="header__logo">
            <img src="../assets/images/logo.png" alt="freedom bank priority">
        </a>
        <div class="header__control">
            <a href="https://api.whatsapp.com/send?phone=77761595595" target="_blank" class="header__control-item mobile-hide">
                <div class="header__control-item-icon">
                    <img src="../assets/images/icons/whatsapp-icon.png" alt="whatsapp">
                </div>
                <div class="header__control-item-text">+7 776 1 595 595</div>
            </a>
            <a href="tel:595" target="_blank" class="header__control-item mobile-hide">
                <div class="header__control-item-icon">
                    <img src="../assets/images/icons/phone-icon.png" alt="phone">
                </div>
                <div class="header__control-item-text">595</div>
            </a>
            <div class="header__control-item">
                <div class="header__control-item-icon lang-switcher" @click="changeLanguage(currentLocale.toLocale)">
                    <span>{{ currentLocale.name }}</span>
                </div>
            </div>
           <!-- <a href="#" target="_blank" class="header__control-item">
              <div class="header__control-item-icon">
                <img src="../assets/images/icons/user.svg" alt="user">
              </div>
              <div class="header__control-item-text">{{ $t('header.enter') }}</div>
            </a> -->
        </div>
    </div>
</template>

<script>
import i18n from '../i18n'

export default {
    name: 'MainHeader',
    data() {
        return {
            languages: [
                {
                    name: 'Рус',
                    locale: 'kk',
                    toLocale: 'ru',
                },
                {
                    name: 'Қаз',
                    locale: 'ru',
                    toLocale: 'kk',
                },
            ]
        }
    },
    computed: {
        activeLocale() {
            return i18n.locale
        },
        currentLocale() {
            let self = this

            return this.languages.find(lang => lang.locale === self.activeLocale)
        }
    },
    methods: {
        changeLanguage(lang) {
            i18n.locale = lang
        }
    }
}
</script>